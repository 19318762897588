<template>
  <div className="main">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.main {
  width: 10rem;
  display: flex;
  flex-direction: column;
}
</style>
