<template>
  <div class="container">
    <Top/>
    <div class="content">
      <Menu :menuList="menuList" v-model:menuChoose="menuChoose"/>
      <div class="itemList">
        <div class="item" v-for="item of show" :key="item.key">
          <img class="item-img" :src="item.img" @click="onclickItem(item)">
          <div class="item-title">
            <span>{{ item.title }}</span>
            <span class="detail" @click="onShow(item)">案例演示<RightOutlined/></span>
          </div>
        </div>
      </div>
    </div>

    <a-modal
        v-model:visible="showCarousel"
        title=""
        width="80%"
        :closable="false"
        :footer="null"
    >
      <a-carousel arrows v-if="showCarousel">
        <template #prevArrow>
          <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
            <left-circle-outlined/>
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-slick-arrow" style="right: 10px">
            <right-circle-outlined/>
          </div>
        </template>
        <img v-for="(image,index) of carouseImages" :src="image" class="cImg" :key="index"/>
      </a-carousel>
    </a-modal>
  </div>
</template>

<script>
import Top from "@/components/top/Top"
import Menu from "@/components/menu/Menu"
import {LeftCircleOutlined, RightCircleOutlined, RightOutlined} from '@ant-design/icons-vue';
import projectData from "@/data/project";
import {message} from "ant-design-vue";
import TYPE from "@/data/type";

export default {
  name: "HomePage",
  components: {Top, Menu, LeftCircleOutlined, RightCircleOutlined, RightOutlined},
  data() {
    return {
      menuChoose: TYPE.ALL,
      menuList: [
        {
          label: "全部",
          key: TYPE.ALL
        },
        {
          label: "智慧海洋",
          key: TYPE.SEA
        },
        {
          label: "智慧城市",
          key: TYPE.CITY
        },
        {
          label: "智慧物联",
          key: TYPE.THINGS
        },
        {
          label: "时空大数据",
          key: TYPE.DATA
        }
      ],
      itemList: [],
      dataSource: projectData,
      showCarousel: false,//走马灯,
      carouselUrl: "",//走马灯链接,
    }
  },
  mounted() {
    this.itemList = this.dataSource;
  },
  computed: {
    show() {
      if (this.menuChoose === TYPE.ALL) {
        return this.dataSource;
      } else {
        return this.dataSource.filter((item) => {
          if (Array.isArray(item.type)) {
            return item.type.indexOf(this.menuChoose) > -1
          } else {
            return item.type.split(",").indexOf(this.menuChoose) > -1
          }
        })
      }
    }
  },
  methods: {
    onclickItem(item) {
      this.carouselUrl = item.url;
      this.carouseImages = item.carouseImages;
      this.showCarousel = true;
    },
    onShow(item) {
      if (item.carouselUrl) {
        window.open(item.carouselUrl, '_blank');
      } else {
        message.warning("该项目暂时无法演示")
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #0b0d10;
  position: relative;
}

.content {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  padding: 0 0.8rem;
  margin-top: 0.25rem;
  min-height: 5rem;
}

.itemList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.3rem;
}

.item {
  border: 1px solid #3a4659;
  outline: 1px solid transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-right: 0.08rem;
  margin-bottom: 0.1rem;

  .item-img {
    width: 1.98rem;
    height: 0.95rem;
    object-fit: cover;
    background: #1d262e;
  }

  .item-title {
    color: white;
    padding: 0.08rem;
    font-size: 0.08rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .detail {
    font-size: 0.06rem;

    &:hover {
      opacity: 0.8;
    }
  }
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 0.3rem;
  height: 0.3rem;
  font-size: 0.3rem;
  margin: 0 0.1rem;
  color: #fff;
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.cImg {
  //border: 1px solid #3a4659;
  background-color: transparent;
}

</style>