<template>
  <div style="position: relative">
    <transition>
      <img :src="img" class="img">
    </transition>
    <div class="desc">
      <div class="videoTitle">立足测绘,跳出测绘</div>
      <div class="video-desc">积极发展新业态</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Top",
  data() {
    return {
      img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/banner.jpg",
      // img: require("@/assets/images/banner.jpg"),
    }
  }
}
</script>

<style scoped>
.img {
  position: relative;
  width: 9rem;
  height: 1.5rem;
  object-fit: cover;
  opacity: 0.9;
  margin-left: 0.5rem;
}

.videoTitle {
  color: #fff;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.05rem;
  font-size: 0.36rem;
}

.video-desc {
  color: #fff;
  font-size: 0.23rem;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.02rem;
}

.desc {
  position: absolute;
  /*font-size: 0.23rem;*/
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>