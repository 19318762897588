import {createRouter, createWebHistory} from "vue-router";
import route_config from "./route_config";

const routes = route_config;

const router = createRouter({
    //history: createWebHashHistory(),
    history: createWebHistory(),//html5模式
    routes: routes,
    // scrollBehavior (to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition
    //     } else {
    //         return { x: 0, y: 0 }
    //     }
    // },
});
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)

});
export default router;
